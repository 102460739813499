import { Component, Input, ViewChild, ElementRef, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { MapConfigurationService } from '../../services/map-configuration.service';
import { HeatMapItem } from '../../models/heat-map-item';
import { HeatmapService } from 'src/app/services/heatmap.service';

@Component({
  selector: 'heat-map',
  templateUrl: './heat-map.component.html',
  styleUrls: ['./heat-map.component.sass'],
  providers: [
    HeatmapService,
    MapConfigurationService
  ]
})
export class HeatMapComponent implements OnChanges, AfterViewInit {
  @ViewChild('heatMap') mapReference: ElementRef;

  @Input() items: Array<HeatMapItem>;

  private map: google.maps.Map;
  private mapOptions: google.maps.MapOptions;
  private commercialDistrict: Array<google.maps.LatLng>;
  private positiveMapItems: Array<Object>;
  private negativeMapItems: Array<Object>;
  private additionalMapItems: Array<Object>;
  private positiveHeatMapLayer: google.maps.visualization.HeatmapLayer;
  private negativeHeatMapLayer: google.maps.visualization.HeatmapLayer;

  constructor(
    private heatmapService: HeatmapService,
    private mapConfigurationService: MapConfigurationService
  ) {
    this.additionalMapItems = heatmapService.additionalItems;
    mapConfigurationService.options.subscribe((options) => {
      this.mapOptions = options;
    })
    mapConfigurationService.commercialDistrict.subscribe((boundaries) => {
      this.commercialDistrict = boundaries;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items && changes.items.currentValue) {
      this.updateMap();
    }
  }

  ngAfterViewInit(): void {
    this.map = new google.maps.Map(this.mapReference.nativeElement, this.mapOptions);
  }

  private updateMap(): void {
    let positiveItems: Array<Object> = this.additionalMapItems;
    let negativeItems: Array<Object> = [];

    if (this.positiveHeatMapLayer) {
      this.positiveHeatMapLayer.setMap(null);
    }

    if (this.negativeHeatMapLayer) {
      this.negativeHeatMapLayer.setMap(null);
    }

    this.items.forEach((item: HeatMapItem): void => {
      if (item.weight >= 0) {
        if (item.weight) {
          positiveItems.push({
            location: new google.maps.LatLng(item.location[0], item.location[1]),
            weight: item.weight
          });
        }
      } else {
        negativeItems.push({
          location: new google.maps.LatLng(item.location[0], item.location[1]),
          weight: -item.weight
        });
      }
    });
    this.negativeMapItems = negativeItems;
    this.positiveMapItems = positiveItems;

    this.positiveHeatMapLayer = new google.maps.visualization.HeatmapLayer({
      data: this.positiveMapItems,
      radius: 20,
      gradient: [
        'rgba(0, 0, 0, 0)',
        'rgba(47, 103, 66, 1)',
        'rgba(34, 143, 73, 1)',
      ]
    });

    this.negativeHeatMapLayer = new google.maps.visualization.HeatmapLayer({
      data: this.negativeMapItems,
      radius: 20,
      gradient: [
        'rgba(0, 0, 0, 0)',
        'rgba(190, 116, 112, 0.7)',
        'rgba(193, 77, 73, 0.7)'
      ]
    });

    this.positiveHeatMapLayer.setMap(this.map);
    this.negativeHeatMapLayer.setMap(this.map);
    this.displayCommercialBoundaries();
  }

  private displayCommercialBoundaries(): void {
    const district = new google.maps.Polygon({
      paths: this.commercialDistrict,
      strokeColor: "rgb(243, 208, 82)",
      strokeOpacity: 0.25,
      strokeWeight: 2,
      fillColor: "rgb(243, 208, 82)",
      fillOpacity: 0.1
    });
    district.setMap(this.map);
  }
}
