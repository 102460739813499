<header class="page-header">
  <h1>Calculating support</h1>
</header>

<p>
  The data visualized below displays the overall sentiment of responses as they relate to the
  establishment of a historic conservation district. Sentiment is calculated for each resident
  based on how each question is answered. Each question of the ten total questions listed in
  survey contributes 10% to the overall sentiment, be it in the negative direction or positive.
</p>
<p>
  To put it more simply, if a resident says "yes" to all the questions they are considered to
  be fully in support. If they respond "no" to all the questions then they are considered fully
  in opposition.
</p>
<p>
  The map below displays that data in relation to the resident's home address. The more positive
  or negative the residents sentiment is the larger the blob.
</p>
<p>
  Residents expressing support of a historic conservation district are <strong>displayed in green</strong>
  whereas those in opposition are red.
</p>

<div class="heat-map" #heatMap></div>

<p class="caption">
  The commercial district was not considered to be included in the historic conservation district at
  this time.
</p>