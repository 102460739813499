import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Resident } from '../../models/resident';
import { HeatMapItem } from '../../models/heat-map-item';
import { Address } from '../../models/address';
import { Question } from '../../models/question';
import { ChartData } from 'chart.js';
import { ResidentsService } from 'src/app/services/residents.service';
import { QuestionsService } from 'src/app/services/questions.service';

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.sass']
})
export class ResultsComponent implements OnInit {
  private residents$: Observable<Array<Resident>>;
  private questions$: Observable<Array<Question>>;

  questions: Array<Question>;
  heatMapItems: Array<HeatMapItem>;
  stackedBarData: ChartData;

  constructor(
    private residentsService: ResidentsService,
    private questionsService: QuestionsService
  ) {
    this.residents$ = residentsService.residentsInVillage;
    this.questions$ = questionsService.questions;
  }

  ngOnInit(): void {
    this.residents$.subscribe((residents): void => {
      this.heatMapItems = this.buildHeatMapData(residents);
    });

    this.questions$.subscribe((questions): void => {
      this.questions = questions;
      this.stackedBarData = this.buildStackedData(questions);
    });
  }

  private buildHeatMapData(residents: Array<Resident>): Array<HeatMapItem> {
    const residentsWithAddresses: Array<Resident> = residents.filter((resident) => {
      const coordinates = resident.address.coordinates;
      return coordinates.lat || coordinates.lng;
    });

    return residentsWithAddresses.map((resident: Resident): HeatMapItem => {
      const address: Address = resident.address;

      return {
        location: [
          address.coordinates.lat,
          address.coordinates.lng
        ],
        weight: resident.totalFavorability
      };
    })
  }

  private buildStackedData(questions): ChartData {
    const labelArray: Array<string> = [];
    const yesData: Array<number> = [];
    const noData: Array<number> = [];

    questions.forEach(question => {
      labelArray.push(question.title);
      yesData.push(question.tally.yes);
      noData.push(-question.tally.no);
    });

    return {
      labels: labelArray,
      datasets: [
        {
          backgroundColor: 'rgba(47, 103, 66, 0.75)',
          label: 'yes',
          data: yesData
        },
        {
          backgroundColor: 'rgba(190, 116, 112, 0.5)',
          label: 'no',
          data: noData
        }
      ]
    }
  }
}
