import { Injectable } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { Resident } from '../models/resident';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ResidentsService {
  public residents: Observable<Array<Resident>>;
  public residentsInVillage: Observable<Array<Resident>>;

  constructor(
    private firestore: AngularFirestore
  ){
    this.residents = firestore.collection<Resident>('residents').valueChanges();
    this.residentsInVillage = firestore.collection<Resident>('residents', (ref) => {
      return ref.where('address.inVillage', '==', true)
    }).valueChanges();
  }
}