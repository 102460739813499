<header class="page-header">
  <h1>Survey Overview</h1>
</header>

<app-totals></app-totals>

<p>
  The bar chart below visualizes an overview of all of the responses
  received from Zionsville Village residents. The chart breaks down each
  of the ten questions and displays the total number of votes in support
  alongside the votes in opposition.
</p>

<div class='chart'>
  <canvas #chart class='chart__canvas'></canvas>
</div>
