import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class MapConfigurationService {
  styles: BehaviorSubject<Array<google.maps.MapTypeStyle>> = new BehaviorSubject<Array<google.maps.MapTypeStyle>>([
    {
      featureType: 'poi',
      stylers: [{ visibility: 'off' }]
    },
    { elementType: 'geometry', stylers: [{ color: '#242f3e' }] },
    { elementType: 'labels.text.stroke', stylers: [{ color: '#242f3e' }] },
    { elementType: 'labels.text.fill', stylers: [{ color: '#746855' }] },
    {
      featureType: 'administrative.locality',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#d59563' }]
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [{ color: '#38414e' }]
    },
    {
      featureType: 'road',
      elementType: 'geometry.stroke',
      stylers: [{ color: '#212a37' }]
    },
    {
      featureType: 'road',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#9ca5b3' }]
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [{ color: '#746855' }]
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.stroke',
      stylers: [{ color: '#1f2835' }]
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#f3d19c' }]
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [{ color: '#17263c' }]
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#515c6d' }]
    },
    {
      featureType: 'water',
      elementType: 'labels.text.stroke',
      stylers: [{ color: '#17263c' }]
    }
  ])

  options: BehaviorSubject<google.maps.MapOptions> = new BehaviorSubject<google.maps.MapOptions>({
    center: { lat: 39.9509315, lng: -86.266392 },
    zoom: 15.3,
    mapTypeControl: false,
    streetViewControl: false,
    rotateControl: false,
    zoomControl: false,
    disableDefaultUI: true,
    draggable: true,
    keyboardShortcuts: false,
    disableDoubleClickZoom: true,
    scrollwheel: false,
    styles: this.styles.getValue()
  });

  commercialDistrict: BehaviorSubject<Array<google.maps.LatLng>> = new BehaviorSubject<Array<google.maps.LatLng>>([
    new google.maps.LatLng(39.952441, -86.261791),
    new google.maps.LatLng(39.952400, -86.262277),
    new google.maps.LatLng(39.952303, -86.262772),
    new google.maps.LatLng(39.952274, -86.263225),
    new google.maps.LatLng(39.950580, -86.262742),
    new google.maps.LatLng(39.950559, -86.262862),
    new google.maps.LatLng(39.948038, -86.262159),
    new google.maps.LatLng(39.948420, -86.259874),
    new google.maps.LatLng(39.950340, -86.260416),
    new google.maps.LatLng(39.950447, -86.259939),
    new google.maps.LatLng(39.951659, -86.260685),
    new google.maps.LatLng(39.952395, -86.260835),
    new google.maps.LatLng(39.952270, -86.261739),
    new google.maps.LatLng(39.952441, -86.261791)
  ])

  constructor() {
    this.styles.subscribe((styles): void => {
      let nextOptions: google.maps.MapOptions = Object.assign({}, this.options.getValue());
      nextOptions.styles = styles;
      this.setOptions(nextOptions);
    });
  }

  public setStyles(styles: Array<google.maps.MapTypeStyle>): void {
    const nextStyles: Array<google.maps.MapTypeStyle> = styles;
    this.styles.next(nextStyles);
  }

  public setOptions(options: google.maps.MapOptions): void {
    const nextOptions: google.maps.MapOptions = options;
    this.options.next(nextOptions);
  }
}
