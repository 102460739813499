import { Injectable } from '@angular/core';

@Injectable()
export class HeatmapService {
  public additionalItems: Array<Object> = [
    {
      location: new google.maps.LatLng(39.953875, -86.262866),
      weight: 10
    },
    {
      location: new google.maps.LatLng(39.953417, -86.264941),
      weight: 10
    },
    {
      location: new google.maps.LatLng(39.950625, -86.263002),
      weight: 10
    },
    {
      location: new google.maps.LatLng(39.955712, -86.261086),
      weight: 10
    },
    {
      location: new google.maps.LatLng(39.954197, -86.262610),
      weight: 10
    },
    {
      location: new google.maps.LatLng(39.952573, -86.262114),
      weight: 10
    },
    {
      location: new google.maps.LatLng(39.955791, -86.262344),
      weight: 10
    },
    {
      location: new google.maps.LatLng(39.949933, -86.270550),
      weight: 10
    },
    {
      location: new google.maps.LatLng(39.952915, -86.260756),
      weight: 10
    },
    {
      location: new google.maps.LatLng(39.950491, -86.268567),
      weight: 10
    },
    {
      location: new google.maps.LatLng(39.952059, -86.266741),
      weight: 10
    }
  ]
}