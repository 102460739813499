import { Injectable } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';

import { Observable } from 'rxjs';
import { Question } from '../models/question';

@Injectable({
  providedIn: 'root'
})
export class QuestionsService {
  public questionsCollection: AngularFirestoreCollection<Question>;
  public questions: Observable<Array<Question>>;

  constructor(
    private firestore: AngularFirestore
  ) {
    this.questionsCollection = firestore.collection<Question>('questions', (ref) => {
      return ref.orderBy('questionNumber');
    });
    this.questions = this.questionsCollection.valueChanges();
  }
}