import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AngularFireModule } from '@angular/fire';
import { GoogleMapsModule } from '@angular/google-maps';
import { environment } from '../environments/environment';
import { HeatMapComponent } from './components/heat-map/heat-map.component';
import { ChartComponent } from './components/chart/chart.component';
import { ResultsComponent } from './components/results/results.component';
import { StackedChartComponent } from './components/stacked-chart/stacked-chart.component';
import { TotalsComponent } from './components/totals/totals.component';

@NgModule({
  declarations: [
    AppComponent,
    ResultsComponent,
    HeatMapComponent,
    ChartComponent,
    TotalsComponent,
    StackedChartComponent
  ],
  imports: [
    GoogleMapsModule,
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase)
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
