<section class="page-content">
  <header class="page-header">
    <h1>Zionsville Historic Conservation District Survey Results</h1>
    <!-- <img src="./assets/images/houses--black-outline.png"> -->
  </header>

  <div class="intro">
    <p>Dear Village Residents,</p>
    <br>
    <p>
      Our Village is at a critical point in its history. Due to its charm, walkability, and urban feel, it has become an
      increasingly popular place to live. As a result the Village is under increasing pressure for development. Homes, some of
      them historic, are being torn down at an increasing pace and the new construction replacing those homes is not always
      compatible with the surrounding structures.
    </p>
    <p>
      Some feel this is fine and not an issue. Others however, are disturbed by this trend and worry that over time our
      Village will lose the charm, uniqueness, and character that has defined it and makes it a unique community in central
      Indiana. They would like more protection for the Village. The current zoning ordinance has limitations and does not
      protect against tear-downs, or new construction not to scale, proportion or compatibility with surrounding structures.
    </p>
    <p>
      We have collected the results from the 2020 survey. We've used that data to gauge the level of desire for the
      establishment of a historic conservation district. Below you will find visual representations of the responses for
      each individual question as well as aggregated data to more clearly portray a comprehensive level of support.
    </p>

    <p>
      Thank-you,
      <br>
      <br>
      The Zionsville Historic Preservation Committee
    </p>
  </div>

  <heat-map [items]="heatMapItems"></heat-map>

  <stacked-chart [chartData]="stackedBarData"></stacked-chart>

  <app-chart
    *ngFor="let question of questions; even as isEven"
    [isEven]="isEven"
    [question]="question"
  ></app-chart>
</section>