import { Component, Input, ViewChild, ElementRef, OnChanges, SimpleChanges } from '@angular/core';

import * as Chart from 'chart.js';

@Component({
  selector: 'stacked-chart',
  templateUrl: './stacked-chart.component.html',
  styleUrls: ['./stacked-chart.component.sass']
})
export class StackedChartComponent implements OnChanges {
  @ViewChild('chart') chartRef: ElementRef;

  @Input() chartData: Chart.ChartData;

  private chart: any;

  ngOnChanges(changes: SimpleChanges) {
    if (Array(changes.chartData.currentValue).length && this.chartRef) {
      this.chart = new Chart(this.chartRef.nativeElement, {
        type: 'horizontalBar',
        data: this.chartData,
        options: {
          title: {
            display: false,
          },
          tooltips: {
            // titleFontFamily: 'Unna',
            mode: 'index',
            enabled: false,
            intersect: false,
            custom: function (tooltipModel) {
              // Tooltip Element
              var tooltipEl = document.getElementById('chartjs-tooltip');

              // Create element on first render
              if (!tooltipEl) {
                tooltipEl = document.createElement('div');
                tooltipEl.id = 'chartjs-tooltip';
                tooltipEl.innerHTML = '<div class="tool-tip-body"></div>';
                document.body.appendChild(tooltipEl);
              }

              // Hide if no tooltip
              if (tooltipModel.opacity === 0) {
                tooltipEl.style.opacity = '0';
                return;
              }

              // Set caret Position
              tooltipEl.classList.remove('above', 'below', 'no-transform');
              if (tooltipModel.yAlign) {
                tooltipEl.classList.add(tooltipModel.yAlign);
              } else {
                tooltipEl.classList.add('no-transform');
              }

              if (tooltipModel.dataPoints) {
                const yes: any = tooltipModel.dataPoints[0];
                const no: any = tooltipModel.dataPoints[1];
                const customTooltip = `
                  <header><h3>${yes.label}</h3></header>
                  <ul class="list">
                    <li class="list__item list__item--yes">
                      <div class="list__icon">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgba(47, 103, 66, 1)">
                          <path d="M0 0h24v24H0V0zm0 0h24v24H0V0z" fill="none" />
                          <path d="M21 12v-2h-9l1.34-5.34L9 9v10h9z" opacity=".3" />
                          <path d="M9 21h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-2c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L14.17 1 7.58 7.59C7.22 7.95 7 8.45 7 9v10c0 1.1.9 2 2 2zM9 9l4.34-4.34L12 10h9v2l-3 7H9V9zM1 9h4v12H1z" />
                        </svg>
                      </div>
                      <div class="list__content">
                        <dl>
                          <dt>Yes:</dt>
                          <dd>${yes.xLabel}</dd>
                        </dl>
                      </div>
                    </li>
                    <li class="list__item list__item--no">
                      <div class="list__icon">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgba(190, 116, 112, 1)">
                          <path d="M0 0h24v24H0V0zm0 0h24v24H0V0z" fill="none" />
                          <path d="M3 12v2h9l-1.34 5.34L15 15V5H6z" opacity=".3" />
                          <path d="M15 3H6c-.83 0-1.54.5-1.84 1.22l-3.02 7.05c-.09.23-.14.47-.14.73v2c0 1.1.9 2 2 2h6.31l-.95 4.57-.03.32c0 .41.17.79.44 1.06L9.83 23l6.59-6.59c.36-.36.58-.86.58-1.41V5c0-1.1-.9-2-2-2zm0 12l-4.34 4.34L12 14H3v-2l3-7h9v10zm4-12h4v12h-4z" />
                        </svg>
                      </div>
                      <div class="list__content">
                        <dl>
                          <dt>No:</dt>
                          <dd>${Math.abs(Number(no.xLabel))}</dd>
                        </dl>
                      </div>
                    </li>
                  </ul>
                `;

                const tooltipRoot = tooltipEl.querySelector('.tool-tip-body');
                tooltipRoot.innerHTML = customTooltip;
              }

              // `this` will be the overall tooltip
              var position = this._chart.canvas.getBoundingClientRect();
              var caretXPosition = window.innerWidth <= 600 ? 0 : tooltipModel.caretX;

              // Display, position, and set styles for font
              tooltipEl.style.opacity = '1';
              tooltipEl.style.position = 'absolute';
              tooltipEl.style.left = position.left + window.pageXOffset + caretXPosition + 'px';
              tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
              tooltipEl.style.pointerEvents = 'none';
            }
          },
          responsive: true,
          scales: {
            xAxes: [{
              stacked: true,
              ticks: {
                callback: function (value, _index, _values) {
                  return Math.abs(Number(value));
                }
              }
            }],
            yAxes: [{
              stacked: true,
              ticks: {
                callback: function (_value, index, _values) {
                  return index+1;
                }
              }
            }]
          },
          legend: {
            display: false
          }
        }
      });
    }
  }
}