import { Component, Input, ViewChild, ElementRef, AfterContentInit, AfterViewInit } from '@angular/core';
import { Question } from '../../models/question';

import * as Chart from 'chart.js';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.sass']
})
export class ChartComponent implements AfterViewInit {
  @ViewChild('chart') chartRef: ElementRef;

  @Input() question: Question;
  @Input() isEven: Boolean = false;

  private chart: Chart;

  ngAfterViewInit(): void {
    const data = {
      type: 'pie',
      data:{
        datasets: [{
          data: [
            this.question.tally.yes,
            this.question.tally.no,
            this.question.tally.unanswered
          ].filter(item => item > 0),
          backgroundColor: [
            'rgba(47, 103, 66, 0.75)',
            'rgba(190, 116, 112, 0.5)',
            'rgba(255, 245, 224, 1)'
          ],
          borderColor: [
            'rgba(47, 103, 66, 1)',
            'rgba(190, 116, 112, 1)',
            'rgba(243, 208, 82, 1)'
          ]
        }],
        labels: [
          'Yes',
          'No',
          'Unanswered'
        ]
      },
      options: {
        legend: {
          display: false
        },
        tooltips: {
          callbacks: {
            label: this.buildChartLabel
          }
        }
      }
    };
    this.chart = new Chart(this.chartRef.nativeElement, data);
  }

  buildChartLabel(tooltipItem, data): string {
    const dataSet = data.datasets[0];
    const total: number = dataSet.data.reduce((accumulator: number, number: number) => accumulator + number);
    const count: number = dataSet.data[tooltipItem.index];

    let label: string = data.labels[tooltipItem.index] || '';

    if (label) {
      label += ': ';
    }

    label += ((count / total) * 100).toFixed(1) + '%';

    return label;
  }
}