<div class="app-wrapper">
  <header>
    <h1>
      <a href="https://www.preservezionsville.com" class="link link--no-underline">
        Zionsville Historic Preservation Committee
      </a>
    </h1>
  </header>
  <nav>
    <ul>
      <li><a href="https://www.preservezionsville.com">Home</a></li>
      <li><a href="https://www.preservezionsville.com/conservation-district">About</a></li>
      <li><a href="https://www.preservezionsville.com/contact">Contact</a></li>
    </ul>
  </nav>

  <router-outlet></router-outlet>
</div>
