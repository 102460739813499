import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ResidentsService } from 'src/app/services/residents.service';
import { Resident } from 'src/app/models/resident';
import { QuestionsService } from 'src/app/services/questions.service';
import { Question } from 'src/app/models/question';
import { AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';

@Component({
  selector: 'app-totals',
  templateUrl: './totals.component.html',
  styleUrls: ['./totals.component.sass']
})
export class TotalsComponent implements OnInit {
  private residents$: Observable<Array<Resident>>;
  private questionsCollection: AngularFirestoreCollection;

  sent: number = 413;
  received: number;

  yourHousePercent: number;
  supportPercent: number;

  constructor(
    private residentsService: ResidentsService,
    private questionsService: QuestionsService
  ) {
    this.residents$ = residentsService.residents;
    this.questionsCollection = questionsService.questionsCollection;
  }

  ngOnInit(): void {
    const nine$: Observable<Question> = this.questionsCollection.doc<Question>('nine').valueChanges();
    const ten$: Observable<Question> = this.questionsCollection.doc<Question>('ten').valueChanges();

    this.residents$.subscribe((residents): void => {
      this.received = residents.length;
    });

    nine$.subscribe((question: Question): void => {
      this.supportPercent = this.calculatePercentage(question);
    });

    ten$.subscribe((question: Question): void => {
      this.yourHousePercent = this.calculatePercentage(question);
    });
  }

  calculatePercentage(question: Question): number {
    const total: number = question.tally.yes + question.tally.no + question.tally.unanswered;
    return question.tally.yes / total
  }
}