<div
  [ngClass]="{'chart': true, 'chart--even': isEven, 'chart--odd': !isEven}"
>
  <div class="chart__number">
    <h3> Question #{{question.questionNumber}}</h3>
  </div>
  <div class="chart__content">
    <h2>{{ question.title }}</h2>
    <ul class="list">
      <li class="list__item list__item--yes">
        <div class="list__icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgba(47, 103, 66, 1)">
            <path d="M0 0h24v24H0V0zm0 0h24v24H0V0z" fill="none" />
            <path d="M21 12v-2h-9l1.34-5.34L9 9v10h9z" opacity=".3" />
            <path d="M9 21h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-2c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L14.17 1 7.58 7.59C7.22 7.95 7 8.45 7 9v10c0 1.1.9 2 2 2zM9 9l4.34-4.34L12 10h9v2l-3 7H9V9zM1 9h4v12H1z" />
          </svg>
        </div>
        <div class="list__content">
          <dl>
            <dt>Yes:</dt>
            <dd>{{ question.tally.yes }}</dd>
          </dl>
        </div>
      </li>
      <li class="list__item list__item--no">
        <div class="list__icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgba(190, 116, 112, 1)">
            <path d="M0 0h24v24H0V0zm0 0h24v24H0V0z" fill="none" />
            <path d="M3 12v2h9l-1.34 5.34L15 15V5H6z" opacity=".3" />
            <path d="M15 3H6c-.83 0-1.54.5-1.84 1.22l-3.02 7.05c-.09.23-.14.47-.14.73v2c0 1.1.9 2 2 2h6.31l-.95 4.57-.03.32c0 .41.17.79.44 1.06L9.83 23l6.59-6.59c.36-.36.58-.86.58-1.41V5c0-1.1-.9-2-2-2zm0 12l-4.34 4.34L12 14H3v-2l3-7h9v10zm4-12h4v12h-4z" />
          </svg>
        </div>
        <div class="list__content">
          <dl>
            <dt>No:</dt>
            <dd>{{ question.tally.no }}</dd>
          </dl>
        </div>
      </li>
      <!-- <li class="list__item" *ngIf="question.tally.unanswered > 0">
        <div class="list__icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgba(243, 208, 82, 1)">
            <path d="M0 0h24v24H0V0zm0 0h24v24H0V0z" fill="none" />
            <path d="M12 6c0-.55-.45-1-1-1H5.82l.66-3.18.02-.23c0-.31-.13-.59-.33-.8L5.38 0 .44 4.94C.17 5.21 0 5.59 0 6v6.5c0 .83.67 1.5 1.5 1.5h6.75c.62 0 1.15-.38 1.38-.91l2.26-5.29c.07-.17.11-.36.11-.55V6zm-2 1.13L7.92 12H2V6.21l1.93-1.93L3.36 7H10v.13zM22.5 10h-6.75c-.62 0-1.15.38-1.38.91l-2.26 5.29c-.07.17-.11.36-.11.55V18c0 .55.45 1 1 1h5.18l-.66 3.18-.02.24c0 .31.13.59.33.8l.79.78 4.94-4.94c.27-.27.44-.65.44-1.06v-6.5c0-.83-.67-1.5-1.5-1.5zm-.5 7.79l-1.93 1.93.57-2.72H14v-.13L16.08 12H22v5.79z" />
            <path d="M3.93 4.28L2 6.21V12h5.92L10 7.13V7H3.36zM14 16.87V17h6.64l-.57 2.72L22 17.79V12h-5.92z" opacity=".3" />
          </svg>
        </div>
        <div class="list__content">
          <dl>
            <dt>Unanswered:</dt>
            <dd>{{ question.tally.unanswered }}</dd>
          </dl>
        </div>
      </li> -->
    </ul>
  </div>
  <div class="chart__visual">
    <canvas #chart></canvas>
  </div>
</div>

